import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { seoKeyWords } from "../helpers/seo"
class About extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="About" keywords={seoKeyWords} />{" "}
        <div className="site-About">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2>About</h2>
                <p>
                  I first made Script Kitty Clothing because I felt there
                  weren't enough coding T-Shirts that fit my style. Our shirts
                  are made with the intention of being unique, humorous and one
                  of a kind.
                </p>
                <h2>Returns</h2>
                <p>
                  Due to all T-Shirts being printed to order, all sales are
                  final. However, if there is a printing / quality issue please
                  let me know and we can work to fix it.
                </p>
                <h2>Shirt Details</h2>
                <p>
                  The Bella + Canvas 3001 t-shirt feels soft and light, with
                  just the right amount of stretch. It's comfortable and the
                  unisex cut is flattering for both men and women. We can't
                  compliment this shirt enough – it's one of our crowd
                  favorites. And it's sure to be your next favorite too!
                </p>
                <ul>
                  <li>100% combed and ring-spun cotton*</li>
                  <li>Fabric weight: 4.2 oz/y² (142 g/m²)</li>
                  <li>Pre-shrunk fabric</li>
                  <li>30 single</li>
                  <li>Tear-away label</li>
                  <li>Shoulder-to-shoulder taping</li>
                  <li>Side-seamed</li>
                </ul>
                <p>
                  *Heather colors are 52% combed and ring-spun cotton/48%
                  polyester. Athletic and Black Heather are 90% combed and
                  ring-spun cotton/10% polyester. Ash color is 99% Airlume
                  combed and ring-spun cotton and 1% poly. Heather Prism Ice
                  Blue is 52% Airlume combed cotton / 48% polyester rest of the
                  Prism colors are 99% Airlume combed and ring-spun cotton and
                  1% poly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default About
